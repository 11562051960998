<!-- 购物车明细 -->

<template>
  <w-dialog ref="shopDialog" class="shopcart ticket-dialog" title="购物车" width="80%" btnWidth="140px" top="4vh"
    confirmText="结算" @wConfirm="wConfirm">
    <div class="ticket-content">
      <div style="padding: 10px;">
        <common-table ref="shopTable" :ispaging="false" :tableData="tableData" tableHeight="370"
          :columns="shopColumns" @onRemoveBtn="onRemoveBtn">
          <template #amount>
            <el-row :gutter="20"
              style="background-color: var(--search-uncheck-bg-color);font-size: 18px;font-weight: bold;margin: 0 1px;">
              <el-col :span="6" style="padding: 10px;">
                <div class="amount">合计</div>
              </el-col>
              <el-col :span="18" style="padding: 10px;">
                <div class="order-totals">{{ total_money }}</div>
              </el-col>
            </el-row>
          </template>
        </common-table>
      </div>
    </div>
  </w-dialog>
</template>

<script>
  import { ref, nextTick, } from 'vue';
  import { SaleApi, } from '@/plugins/api.js'
  import {
    ElMessage
  } from 'element-plus'

  export default {
    emits: ["onSettlement", "onRetrieve"],
    setup(props, { emit }) {
      const tableData = ref([]);  // 购物车数据
      const shopDialog = ref(null);  // 购物车弹框对象
      const shopTable = ref(null);  // 表格对象
      const total_money = ref(0);  // 合计
      const shopColumns = ref([
        {
          prop: "name",
          label: "商品名称",
        },
        {
          prop: "num",
          label: "数量",
        },
        {
          prop: "price",
          label: "单价",
        },
        {
          prop: "total",
          label: "价格",
        },
        {
          prop: "mobile",
          label: "会员信息",
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 100,
          bottons: [
            {
              name: "移除",
              action: "onRemoveBtn",
              token: "n_Y07Z8iMwoVoiQWYmLLnHnVNgMf0Y",
              className: "theme-font-btn",
            },
          ],
        },
      ])
      /**
       * 
       * 打开弹框
       * 
       * */
      const openDialog = () => {
        shopDialog.value.show();
        getShopCartList();
      }
      /**
       * 
       * 关闭弹框
       * 
       * */
      const closeDialog = () => {
        shopDialog.value.close();
      }
      /**
       * 
       * 获取购物车数据
       * 
       * **/
      const getShopCartList = () => {
        SaleApi.getShopCartList().then(res => {
          if (res.Code == 200) {
            tableData.value = res.Data.list;
            total_money.value = res.Data.total_money;
            nextTick(() => {
              shopTable.value.tableLoad();
            })
          } else {
            ElMessage.error(res.Message);
          }
        })
      }
      /**
       * 
       * 移除
       * 
       * **/
      const onRemoveBtn = (row) => {
        let params = {
          ticket_data: [],
          type: 2,
          uuid: row.uuid,
          goods_data: [{
            buy_no: row.num,
          }],
        };
        // console.log("params", params)
        SaleApi.addShopCart(params).then(res => {
          if (res.Code == 200) {
            ElMessage.success("移除成功");
            emit("onRetrieve");
            getShopCartList();
          } else {
            ElMessage.error(res.Message);
          }
        })
      }
      /**
       * 
       * 结算按钮
       * 
       * **/
      const wConfirm = () => {
        emit("onSettlement", total_money.value);
      }

      return {
        tableData,
        shopDialog,
        shopTable,
        shopColumns,
        wConfirm,
        openDialog,
        getShopCartList,
        total_money,
        onRemoveBtn,
        closeDialog,
      };
    }
  }

</script>

<style lang="scss">
  .shopcart {
    .el-dialog__body {
      padding: 0;
    }

    .order-totals {
      margin-left: 100px;
      color: var(--text-color);
    }

    .amount {

      color: var(--text-color);
    }

    .ticket-dialog {
      .el-dialog__body {
        padding: 0;

        .ticket-content {
          min-height: 500px;
          display: flex;

          .right-content {
            width: 40%;
            border-left: 1px solid #eee;
            overflow-y: auto;
            padding: 20px;

            .el-empty__image {
              width: 100px;
            }

            .right-title {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 20px;



              .enter-switch {
                display: flex;
                align-items: center;

                >span {
                  color: var(--text-gray-color);
                  padding-right: 6px;
                }
              }
            }

            .visitor-item {
              border: 2px dashed #eee;
              padding: 10px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: relative;

              .close-icon {
                position: absolute;
                right: 4px;
                top: 4px;

                >i {
                  font-size: 16px;
                  font-weight: bold;
                  cursor: pointer;

                  &:hover {
                    color: var(--theme-color);
                  }
                }
              }

              .upload-box {
                text-align: right;
                display: flex;
                align-items: center;

                .img-container {
                  .el-dropdown {
                    position: absolute;
                    color: var(--text-color);
                    right: 16px;
                    bottom: 20px;
                    cursor: pointer;
                  }

                  .face-img {
                    position: relative;

                    .el-image {
                      width: 56px !important;
                      height: 56px !important;
                      border-radius: 6px;
                    }

                    .del {
                      position: absolute;
                      top: 0;
                      right: 0;
                      height: 13px;
                      width: 18px;
                      line-height: 13px;
                      color: var(--text-white-color);
                      background-color: rgba(0, 0, 0, 0.5);
                      border-radius: 0 4px 0 4px;
                      text-align: center;
                      font-size: 12px;
                      cursor: pointer;
                    }
                  }
                }
              }

              .visitor-form {
                flex: 1;

                .el-form-item__content {
                  height: 20px;
                  line-height: 20px;
                }

                .el-form-item__label {
                  padding-right: 0;
                  user-select: none;
                }

                .el-input__inner {
                  padding-right: 10px;
                }

                .el-form-item {
                  /* width: 80%; */
                  align-items: center;
                  margin-bottom: 8px;

                  .el-input__inner {
                    height: 20px;
                    line-height: 20px;
                  }

                  .el-form-item__error {
                    top: 68%;
                    left: 15px;
                  }
                }

                .el-form-item__content .el-input__inner,
                .el-form-item__content .el-textarea__inner {
                  background-color: transparent;
                }
              }
            }
          }

          .left-content {
            max-height: 570px;
            flex: 1;
            overflow-y: auto;
            padding: 20px;

            .basic-info {
              display: flex;
              justify-content: space-around;
              width: 100%;
              background-color: var(--theme-bg-color);
              padding: 10px;
              border-radius: 4px;

              .ticket-title {
                color: var(--text-third-color);
                font-weight: 700;
                font-size: 16px;
              }

              .basic-item {
                /* margin-top: 10px; */
                /* width: 100%; */
                color: var(--text-third-color);

                >span {
                  color: var(--text-gray-color);
                }
              }
            }

            .ticket-form {
              margin-top: 20px;

              .total-money {
                color: var(--text-third-color);
                margin-bottom: 10px;

                >span {
                  margin-left: 4px;
                  color: #0072F6;
                  font-weight: bold;
                  font-size: 20px;
                }
              }

              .el-form-item__label {
                color: var(--text-third-color);
                padding-bottom: 4px;
              }
            }
          }
        }
      }

      .el-dialog__footer {
        padding: 10px;
        border-top: 1px solid var(--border-grey-color);
      }
    }
  }
</style>