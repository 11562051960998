<!-- 录入通行证 -->

<template>
  <div class="net-config-dialog">
    <w-dialog ref="dialogRef" title="录入通行证" width="60%" top="6vh" @wConfirm="confirm" :hideFooter="isHideFooter">
      <div class="ticket-content" v-loading="payTypeLoading">
        <div class="flex-btw" style="margin-bottom: 20px;">
          <div v-if="memberInfo.length > 0" class="flex"
            style="background-color: var(--theme-bg-color);border-radius: 20px;">
            <div v-for="(item, i) in memberInfo" :index="i">
              <div style="padding: 10px 20px;" :class="active == i ? 'tabActive':'tabs'" @click="onClickTab(i)">
                {{item.mcd_name }}
              </div>
            </div>
          </div>
          <div class="enter-title">录入日期：{{ dateDay }}</div>
        </div>

        <div v-if="maxNumber == '0'" class="out-times">手环码已录完</div>
        <div v-else>
          <div class="enter-title">所属会员：{{entryInfo.m_mobile}}</div>
          <div v-if="entryInfo.mcd_stt_id == '1'">
            <div class="enter-title" v-if="fileList.length > 0">人脸录入</div>
            <img-upload uploadTitle="" suggestText="" :fileList="fileList" :isShowUpload="false" :noReplaceImg="false">
            </img-upload>
          </div>

          <div v-else style="width: 300px;">
            <div class="enter-title">
              消费次数
              <span class="consu-number">（最大可用{{maxNumber}}次）</span>
            </div>
            <KeyBoard v-if="enterEype == 'sale' || enterEype == 'ticket'" ref="keyBoardRef" :type="'number'"
              v-model="consuNum" :placeholder="'请输入消费次数'" @inputEvent="consuNumChange">
            </KeyBoard>

            <el-input v-model="consuNum" type="number" placeholder="请输入消费次数" @change="consuNumChange"
              oninput="if(value < 1) value = 1" v-else />
          </div>

          <div class="enter-title" v-if="childrenInfo.length > 0">儿童手环录入</div>
          <div class="visitor-item">
            <!--  @submit.native.prevent：阻止enter键事件整个页面刷新问题 -->
            <el-form label-position="right" class="visitor-form" style="display: flex;flex-wrap: wrap;"
              @submit.native.prevent>
              <el-form-item prop="paper_sn" label="" v-for="(el, index) in childrenInfo" :key="index">
                <el-input v-model="el.paper_sn" autocomplete="off" placeholder="请用扫码枪识别手环码" :id="'children' + index"
                  @keyup.enter.native="handleSn('children', index, index + 1)" clearable @blur="childrenBlur" />
              </el-form-item>
            </el-form>
          </div>

          <div class="enter-title" v-if="adultInfo.length > 0">成人手环录入</div>
          <div class="visitor-item">
            <el-form label-position="right" class="visitor-form" style="display: flex;flex-wrap: wrap;"
              @submit.native.prevent>
              <el-form-item prop="paper_sn" label="" v-for="(el, index) in adultInfo" :key="index">
                <el-input v-model="el.paper_sn" autocomplete="off" placeholder="请用扫码枪识别手环码" :id="'adult' + index"
                  @keyup.enter.native="handleSn('adult', index, index + 1)" clearable @blur="childrenBlur" />
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, nextTick } from "vue";
  import { ScenicApi, VipApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import dayjs from "dayjs";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import KeyBoard from "@/components/key-board/keyboard.vue";
  import { duplicates, } from "@/utils/common.js";
  export default {
    emits: ["determine"],
    components: {
      imgUpload,
      KeyBoard,
    },
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 弹框对象
      const dateDay = ref(dayjs(new Date()).format("YYYY-MM-DD"));  // 当天日期
      const childrenInfo = ref([]);  // 儿童信息
      const adultInfo = ref([]);  // 成人信息
      const fileList = ref([]);  // 人脸数据
      const memberInfo = ref([]);  // 会员卡数据
      const entryInfo = ref('');  // 会员卡数据
      const consuNum = ref('1');  //  消费次数
      const maxNumber = ref('');  // 最大可用次数
      const mcd_id = ref('');  // 会员id
      const enterEype = ref('');  // 类型
      const keyBoardRef = ref(null);  // 软键盘对象
      const informationObj = ref({
        m_realname: "",
      });
      const active = ref(0);  // 下标
      const payTypeLoading = ref(false);
      const isHideFooter = ref(null);  // 是否显示底部操作按钮
      /**
      *
      * 切换
      *
      * */
      const onClickTab = (i) => {
        payTypeLoading.value = true;
        active.value = i;
        mcd_id.value = memberInfo.value[i].mcd_id;
        consuNum.value = '';
        childrenInfo.value = [];
        adultInfo.value = [];
        fileList.value = [];
        getFoundation();
      }
      /**
      *
      * 打开弹框
      *
      * */
      const openDialog = (id, type) => {
        enterEype.value = type;
        consuNum.value = '1';
        childrenInfo.value = [];
        adultInfo.value = [];
        fileList.value = [];
        if (type == 'ticket') {
          // 销售管理-线下售票-进入
          ScenicApi.getMemberCardByOrder({ sn: id }).then((res) => {
            if (res.Code == 200) {
              memberInfo.value = res.Data ? res.Data : [];
              mcd_id.value = res.Data ? res.Data[0].mcd_id : '';
            } else {
              ElMessage.error(res.Message);
            }
            if (!mcd_id.value) {
              return false;
            }
            getFoundation();
            dialogRef.value.show();
          });
        } else if (type == 'member') {
          // 会员服务-会员卡-进入
          mcd_id.value = id;
          getInfo();
        } else {
          // 销售管理-会员卡-进入
          mcd_id.value = id;
          if (!mcd_id.value) {
            return false;
          }
          getFoundation();
          dialogRef.value.show();
        }
      }
      /**
      *
      * 关闭弹框
      *
      * */
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
      *
      * 会员服务、线下售票-录入前获取会员卡信息
      *
      * */
      const getInfo = () => {
        if (!mcd_id.value) {
          return false;
        }
        VipApi.getBeforeCardReceiveInfo({ mcd_id: mcd_id.value }).then((res) => {
          if (res.Code == 200) {
            handleInfo(res);
            dialogRef.value.show();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
      *
      * 销售管理-会员卡--录入前获取会员卡信息
      *
      * */
      const getFoundation = () => {
        ScenicApi.getBeforeCardReceiveInfo({ mcd_id: mcd_id.value }).then((res) => {
          if (res.Code == 200) {
            payTypeLoading.value = false;
            handleInfo(res);
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
      *
      * 处理结果
      *
      * */
      const handleInfo = (res) => {
        entryInfo.value = res.Data;
        maxNumber.value = res.Data.receive_record_data.max_use_count;
        isHideFooter.value = maxNumber.value == 0;
        if (maxNumber.value == 0) {
          return false;
        }
        // 人脸数据
        if (entryInfo.value.child_img.length > 0) {
          entryInfo.value.child_img.forEach(item => {
            fileList.value.push({ file_url: item, });
          })
        }
        // 儿童数量
        if (entryInfo.value.receive_record_data.once_child_ticket_count > 0) {
          for (var i = 0; i < + entryInfo.value.receive_record_data.once_child_ticket_count; i++) {
            const arr = JSON.parse(JSON.stringify(informationObj.value));
            childrenInfo.value.push(arr);
          }
        }
        // 成人数量
        if (entryInfo.value.receive_record_data.once_audit_ticket_count > 0) {
          for (var i = 0; i < + entryInfo.value.receive_record_data.once_audit_ticket_count; i++) {
            const arr = JSON.parse(JSON.stringify(informationObj.value));
            adultInfo.value.push(arr);
          }
        }
        // 如果是销售-会员卡进入
        if ((enterEype.value == 'sale' || enterEype.value == 'ticket') && entryInfo.value.mcd_stt_id == '2') {
          nextTick(() => {
            keyBoardRef.value.clickKey('1');
          })
        }
      }
      /**
      *
      * 输入次数
      *
      * */
      const consuNumChange = () => {
        if (maxNumber.value > 0) {
          if (consuNum.value > maxNumber.value) {
            ElMessage.error(`最大可用${maxNumber.value}次`);
            consuNum.value = maxNumber.value;
            // return false;
          }
        }
        childrenInfo.value.splice(entryInfo.value.receive_record_data.once_child_ticket_count);
        adultInfo.value.splice(entryInfo.value.receive_record_data.once_audit_ticket_count);
        let addChildrenNum = entryInfo.value.receive_record_data.once_child_ticket_count * consuNum.value - entryInfo.value.receive_record_data.once_child_ticket_count,
          addAdultNum = entryInfo.value.receive_record_data.once_audit_ticket_count * consuNum.value - entryInfo.value.receive_record_data.once_audit_ticket_count;
        // 儿童
        if (childrenInfo.value.length > entryInfo.value.receive_record_data.once_child_ticket_count * consuNum.value) {
          return false;
        }
        if (adultInfo.value.length > entryInfo.value.receive_record_data.once_audit_ticket_count * consuNum.value) {
          return false;
        }
        if (addChildrenNum > 0) {
          for (var i = 0; i < + addChildrenNum; i++) {
            const arr = JSON.parse(JSON.stringify(informationObj.value));
            childrenInfo.value.push(arr);
          }
        }
        // // 成人
        if (addAdultNum > 0) {
          for (var i = 0; i < + addAdultNum; i++) {
            const arr = JSON.parse(JSON.stringify(informationObj.value));
            adultInfo.value.push(arr);
          }
        }
      }
      /**
      *
      * 录入确定按钮
      *
      * */
      const confirm = () => {
        const parmas = {
          receive_record_child: [],  // 儿童
          receive_record_audit: [],  // 成人
          mcd_id: mcd_id.value,  // 会员id
        };
        let repeatArr = [];
        // 处理儿童信息
        childrenInfo.value.forEach(item => {
          if (item.paper_sn) {
            parmas.receive_record_child.push(item.paper_sn);
            repeatArr.push(item.paper_sn);
          }
        })
        // 处理成人信息
        adultInfo.value.forEach(item => {
          if (item.paper_sn) {
            parmas.receive_record_audit.push(item.paper_sn);
            repeatArr.push(item.paper_sn);
          }
        })
        if (duplicates(repeatArr).length > 0) {
          ElMessage.error('重复录入!');
          return false;
        }
        // console.log("确定按钮", parmas, childrenInfo.value, adultInfo.value)
        emit("determine", parmas);
      }
      /**
      *
      * 快捷录入判断是否重复
      *
      * */
      const childrenBlur = () => {
        let braceletArr = [];
        // 处理儿童信息
        childrenInfo.value.forEach(item => {
          if (item.paper_sn) {
            braceletArr.push(item.paper_sn);
          }
        })
        // 处理成人信息
        adultInfo.value.forEach(item => {
          if (item.paper_sn) {
            braceletArr.push(item.paper_sn);
          }
        })
        if (duplicates(braceletArr).length > 0) {
          ElMessage.error('重复录入!');
          return false;
        }
      }
      /**
      *
      * 光标自动下一个输入框
      *
      * */
      const handleSn = (data, index, index2) => {
        childrenBlur();
        if (data == 'children') {
          // 儿童
          if (index2 == childrenInfo.value.length) {
            // 如果当前光标在儿童最后一行，则光标跳成人第一行
            document.getElementById('adult0').focus();
          } else {
            document.getElementById(data + index2).focus();
          }
        } else {
          // 成人
          if (index2 !== adultInfo.value.length) {
            document.getElementById(data + index2).focus();
          }
        }
      }
      /**
      *
      * 自动切换
      *
      * */
      const automaticSwitching = () => {
        if (active.value == (memberInfo.value.length - 1)) {
          onClickTab(active.value);
        } else {
          onClickTab(Number(active.value) + 1);
        }
      }

      return {
        openDialog,
        closeDialog,
        dialogRef,
        adultInfo,
        dateDay,
        childrenInfo,
        fileList,
        getInfo,
        memberInfo,
        entryInfo,
        informationObj,
        handleSn,
        confirm,
        consuNum,
        maxNumber,
        consuNumChange,
        enterEype,
        handleInfo,
        keyBoardRef,
        active,
        onClickTab,
        childrenBlur,
        automaticSwitching,
        payTypeLoading,
        isHideFooter,
      };
    },
  };
</script>

<style lang="scss">
  .net-config-dialog {
    .out-times {
      font-size: 16px;
      margin: 60px 10px;
    }
    .ticket-content {
      max-height: 470px;
      overflow-y: auto;
    }

    .enter-title {
      color: var(--text-color);
      font-size: 14px;
      margin: 10px 0;
    }

    .consu-number {
      color: var(--error-color);
    }

    .visitor-item {
      padding: 10px 0;
      position: relative;

      .visitor-form {
        flex: 1;

        .el-form-item__content {
          line-height: 20px;
        }

        .el-form-item__label {
          padding-right: 0;
          user-select: none;
        }

        .el-input__inner {
          padding-right: 10px;
        }

        .el-form-item {
          margin-right: 10px;
          padding: 10px 0;
          border: 2px dashed #eee;
          align-items: center;
          margin-bottom: 8px;

          .el-input__inner {
            height: 20px;
            line-height: 20px;
          }

          .el-form-item__error {
            top: 68%;
            left: 15px;
          }
        }

        .el-form-item__content .el-input__inner,
        .el-form-item__content .el-textarea__inner {
          background-color: transparent;
        }
      }
    }


    .el-dialog__body {
      .net-config-content {
        height: 400px;
        overflow-y: auto;
      }
    }

    .el-divider--horizontal {
      border-top: 1px #eee solid;
    }

    .action-btn {
      color: var(--theme-color);
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }

    .add-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ticket-title {
        font-size: 16px;
        color: var(--text-color);
        font-weight: bold;
      }
    }

    .net-config-row {
      border: 2px dashed #eee;
      margin: 0 0 16px 0 !important;
      padding: 16px 10px;

      .el-form-item {
        margin-bottom: 0;

        .el-input-group__append {
          background-color: var(--search-uncheck-bg-color);
          border: none;
        }
      }

      .delete-btn {
        text-align: right;
        height: 40px;
        line-height: 40px;
      }
    }

    .tabActive {
      background-color: var(--btn-bg-color);
      color: var(--text-white-color);
      border-radius: 20px;
    }

    .tabs {

      color: var(--text-third-color);

      /* background-color: var(--search-bg-color); */
    }
  }
</style>