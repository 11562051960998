<!-- 购物车 -->

<template>
	<div class="shop-cart" v-show="isShowShopCart" @click="onClickCart">
		<el-badge :value="badgeVal" class="item" :max="99" type="primary">
			<Goods style="width: 22px;height: 22px;margin-left: 12px;" />
		</el-badge>
		<div>购物车</div>
	</div>
</template>

<script setup>
	import { Goods } from "@element-plus/icons-vue";

	const emit = defineEmits(['onClickCart',]);
	const props = defineProps({
		// 徽章值
		badgeVal: {
			type: [String, Number],
			default: 0,
		},
		// 是否显示
		isShowShopCart: {
			type: Boolean,
			default: false,
		},
	});
	/**
	 * 
	 * 点击
	 * 
	 * **/
	const onClickCart = () => {
		emit('onClickCart');
	}
</script>

<style lang="scss" scoped>
	.shop-cart {
		position: fixed;
		bottom: 200px;
		right: 20px;
		background-color: #0072F6;
		padding: 10px 8px;
		color: #fff;
		border-radius: 6px;
		cursor: pointer;
		z-index: 10;
	}
</style>