<template>
	<div v-show="false" id="printHtmlId" style="margin-bottom: 100px;">
		<div v-for="(item,i) in printData.goods" :key="i">
			<div style="text-align:center;font-size: 13px;">{{item.title}}</div>
			<div style="font-size: 13px;">下单门店：{{item.merchant_name}}</div>
			<div style="border-bottom:1px dashed #eee;font-size: 13px;padding: 5px 0;">下单时间：{{item.sale_date}}</div>
			<div v-if="item.number_sn"
				style="border-bottom:1px dashed #eee;font-size: 13px;padding: 5px 0;font-weight: bold;">
				取单号：{{item.number_sn}}</div>
			<table>
				<tr>
					<td style="font-size: 13px;">商品</td>
					<td style="font-size: 13px;">价格</td>
					<td style="font-size: 13px;">数量</td>
					<td style="font-size: 13px;">金额</td>
				</tr>
				<tr v-for="(goods,index ) in item.goods_list" :key="index" style="padding: 5px 0;">
					<td style="text-align: center;font-size: 11px;">{{goods.title}}</td>
					<td style="text-align: center;font-size: 11px;">{{goods.price}}</td>
					<td style="text-align: center;font-size: 11px;">{{goods.buy_no}}</td>
					<td style="text-align: center;font-size: 11px;">{{goods.money}}</td>
				</tr>
			</table>
			<div style="border-top:1px dashed #eee;display: flex;justify-content: space-between;padding: 5px 0;">
				<div style="font-size: 13px;">总计 <span style="font-size: 13px;">{{item.money}}</span></div>
				<div style="font-size: 13px;">优惠 <span style="font-size: 13px;">{{item.yh_money}}</span> </div>
			</div>
		</div>

		<div v-for="(items,p) in printData.ticket" :key="p" style="margin-top: 40px;">
			<div style="text-align:center;font-size: 13px;">{{items.title}}</div>
			<div style="font-size: 13px;">下单园区：{{items.scenic_name}}</div>
			<div style="border-bottom:1px dashed #eee;font-size: 13px;padding: 5px 0;">下单时间：{{items.sale_date}}</div>
			<table>
				<tr>
					<td style="font-size: 13px;">商品</td>
					<td style="font-size: 13px;">价格</td>
					<td style="font-size: 13px;">数量</td>
					<td style="font-size: 13px;">金额</td>
				</tr>
				<tr v-for="(ticket,t ) in items.ticket_list" :key="t" style="padding: 5px 0;">
					<td style="text-align: center;font-size: 11px;">{{ticket.ticket_name}}</td>
					<td style="text-align: center;font-size: 11px;">{{ticket.ticket_price}}</td>
					<td style="text-align: center;font-size: 11px;">{{ticket.ticket_num}}</td>
					<td style="text-align: center;font-size: 11px;">{{ticket.ticket_money}}</td>
				</tr>
			</table>
			<div style="border-top:1px dashed #eee;display: flex;justify-content: space-between;padding: 5px 0;">
				<div style="font-size: 13px;">总计 <span style="font-size: 13px;">{{items.money}}</span></div>
				<div style="font-size: 13px;">优惠 <span style="font-size: 13px;">{{items.yh_money}}</span> </div>
			</div>
		</div>

		<div style="
		border-bottom: 1px dashed #bbb;
		position: relative;
		margin-top: 10px; margin-bottom: 50px;">
			<div style="
		  position: absolute;
		  background-color: var(--text-white-color);
		  width: 100px;
		  left: 50%;
		  margin-left: -50px;
		  text-align: center;
		  top: -10px;
		  z-index: 2;
		  font-size: 13px;
		">
				本次打印结束
			</div>
		</div>
		<div style="height: 1px;background-color: rgba(255, 255, 255, 0.1);font-size: 1px;padding-top:120px;">&nbsp;-
		</div>
	</div>
</template>

<script setup>
	import {
		ref, nextTick
	} from 'vue';
	import {
		printOut
	} from "@/utils/common.js";
	const printData = ref({ goods: [], ticket: [] });
	const setDataAndPrint = (data) => {
		printData.value = data;
		nextTick(() => {
			printOut('printHtmlId')
		})
	}

	defineExpose({
		setDataAndPrint
	})
</script>

<style>
</style>