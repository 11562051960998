<!-- 购物车结算 -->

<template>
  <div>
    <w-dialog ref="settleDialog" class="ticket-dialog" title="结算" width="40%" btnWidth="140px" top="4vh"
      :hideFooter="true">
      <div class="shop-settlement">
        <div class="dialog-pay" v-loading="payTypeLoading">
          <h2 class="text-center">￥{{ allMoney }}</h2>
          <div class="text-center skin-text-color">请选择付款方式</div>
          <div class="grid-4 pay-type">
            <div v-for="(item,i) in payType" @click="checkPayType(item,i)" :key="i" style="margin: 0 auto;">
              <img :src="item.icon" class="pay-icon" />
              <div class="text-center skin-text-color">{{item.orp_name}}</div>
            </div>
          </div>
        </div>
      </div>
    </w-dialog>

    <!-- 支付 -->
    <StatusDialog ref="loadingDialog" :type="1" :title="lastPayway ? '等待支付中' : '确认订单中'" :btnWidth="'200px'"
      @lConfirm="CloseLoadingDialog" :hideFooter="true" :isShowBtn="true">
      <div class="status-tips">
        <div v-if="lastPayway">
          请提醒游客使用 <span>{{ lastPayway }}</span> 支付·
        </div>
        <el-input v-if="lastPayway && lastPayway != '现金' &&  lastPayway != 'POS'" class="barcode-input"
          v-model="barCodeInput" ref="barCodeInputRef" @keyup.enter="barCodeInfo" placeholder="请将光标聚焦在输入框内扫码收款">
        </el-input>
      </div>
    </StatusDialog>

    <!-- 支付成功 -->
    <StatusDialog ref="succesDialog" :type="2" title="收费成功" @lConfirm="CloseStatusDialog" @rConfirm="onEntryPassBtn"
      :confirmText="confirmText" :isShowBtn="true">
    </StatusDialog>

    <!-- 支付失败 -->
    <StatusDialog ref="errorDialog" :type="3" :title="errorTitle" cancelText="取消" confirmText="再次支付"
      @lConfirm="CloseErrorDialog" :isShowBtn="false">
    </StatusDialog>

    <!-- 录入通行证 -->
    <EntryPass ref="entryPassRef" @determine="determine"></EntryPass>

    <!-- 打印小票 -->
    <shopCartPrint ref="printInfo"></shopCartPrint>
  </div>
</template>

<script>
  import { ref, nextTick, } from 'vue';
  import StatusDialog from "@/components/status-dialog/status-dialog.vue";
  import EntryPass from "./EntryPass.vue";
  import shopCartPrint from "@/components/print-info/shopCartPrint.vue";
  import { SaleApi, ScenicApi, OrderApi, } from '@/plugins/api.js'
  import { ElMessage } from 'element-plus'
  import aliPay from '@/assets/img/icon/alipay.png'
  import wechat from '@/assets/img/icon/wechat.png'
  import money from '@/assets/img/icon/money.png'
  import pos from '@/assets/img/icon/pos.png'

  export default {
    components: {
      StatusDialog,
      EntryPass,
      shopCartPrint,
    },
    emits: ["obtainShopData",],
    setup(props, { emit }) {
      const allMoney = ref(0);  // 
      const settleDialog = ref(null);
      const payTypeLoading = ref(false);
      const payType = ref([]);
      const lastPayway = ref('');
      const loadingDialog = ref(null);
      const barCodeInput = ref("");  // 支付输入框内容
      const barCodeInputRef = ref(null);  // 支付输入框对象
      const succesDialog = ref(null);
      const confirmText = ref('确定');
      const lastOrderSn = ref("");
      const errorDialog = ref(null);
      const errorTitle = ref("订单支付失败");
      const entryPassRef = ref(null);
      const printInfo = ref(null);
      /**
       * 
       * 打开弹框
       * 
       * */
      const openDialog = (val) => {
        settleDialog.value.show();
        allMoney.value = val;
        getPayWayCombine();
      }
      /**
       * 
       * 获取支付方式
       * 
       * */
      const getPayWayCombine = () => {
        SaleApi.getPayWayCombine().then(res => {
          if (res.Code === 200 && res.Data.length != 0) {
            payType.value = res.Data.map(resp => {
              if (resp.orp_id == '4') {
                resp.icon = aliPay;
              }
              if (resp.orp_id == '2') {
                resp.icon = wechat;
              }
              if (resp.orp_id == '1') {
                resp.icon = money;
              }
              if (resp.orp_id == '8') {
                resp.icon = pos;
              }
              return resp;
            })
          } else {
            let msg = res.Message ? res.Message : "获取支付方式失败！";
            ElMessage.error(msg);
          }
        })
      }
      /**
       * 
       * 选择支付方式
       * 
       * */
      const checkPayType = (item, i) => {
        payTypeLoading.value = true;
        // 选择支付方式名称
        lastPayway.value = item.orp_name;
        createShopCartOrder(item.orp_id);
      }

      /**
       * 
       * 创建订单
       * 
       * */
      const createShopCartOrder = (pay_way) => {
        lastOrderSn.value = '';
        SaleApi.generateOrder({ pay_way }).then(res => {
          if (res.Code === 200) {
            // 获取支付码
            const orderSnParmas = {
              order_sn: res.Data.combine_sn,
            };
            payTypeLoading.value = false;
            // 显示加载中弹框
            loadingDialog.value.show();
            setTimeout(() => {
              // 获取订单信息
              getOrderInfo(orderSnParmas);
            }, 2000);
            settleDialog.value.close();
          } else {
            ElMessage.error(res.Message);
          }
        })
      }
      /**
       * 
       * 获取订单信息
       * 
       * */
      function getOrderInfo(orderSnParmas) {
        ScenicApi.orderInfo(orderSnParmas).then((res) => {
          if (res.Code == 200) {
            // 支付方式名称
            lastPayway.value = res.Data.payway_name;
            lastOrderSn.value = res.Data.or_combine_sn;
            if (res.Data.or_pay_status == 2) {  // 支付方式不需要扫码
              //支付成功弹框
              succesDialog.value.show();
              // 关闭加载中弹框
              loadingDialog.value.close();
              entryPassRef.value.openDialog(res.Data.or_combine_sn, 'ticket');
              clearShopCart();  // 清空购物车数据
              lodopSn();
            } else if (res.Data.or_pay_status == 1) {  // 支付方式需要扫码
              // 订单编号
              nextTick(() => {
                // 鼠标定位扫码输入框
                barCodeInputRef.value.focus();
              });
            }
          }
        });
      }
      /**
       * 
       * 支付成功后调用清除购物车
       * 
       * */
      const clearShopCart = () => {
        SaleApi.clearShopCart().then(res => {
          emit("obtainShopData", 2);
          if (res.Code !== 200) {
            ElMessage.error(res.Message);
          }
        })
      }
      /**
       * 
       * 扫码后enter事件
       * 
       * */
      function barCodeInfo() {
        const lastParmas = {
          order_sn: lastOrderSn.value,
          auth_code: barCodeInput.value,
        };
        barCodeInput.value &&
          ScenicApi.barCodeInfo(lastParmas).then((res) => {
            if (res.Code == 200) {
              if (res.Data.Code == 200) {
                // 支付成功
                barCodeInput.value = "";
                succesDialog.value.show();
                loadingDialog.value.close();
                clearShopCart();  // 清空购物车数据
                lodopSn();
                entryPassRef.value.openDialog(lastOrderSn.value, 'ticket');
              } else if (res.Data.Code == 205) {
                setTimeout(() => {
                  barCodeInfo();
                }, 3000);
              } else {
                // 支付失败
                barCodeInput.value = "";
                errorTitle.value = res.Data.Message || "订单支付失败";
                loadingDialog.value.close();
                errorDialog.value.show();
              }
            } else {
              loadingDialog.value.close();
              errorDialog.value.show();
            }
          });
      }
      /**
       * 
       * 支付点击返回按钮
       * 
       * */
      function CloseLoadingDialog() {
        barCodeInput.value = "";
        loadingDialog.value.close();
      }
      /**
       * 
       * 支付成功后点击录入通行证/确定按钮
       * 
       * */
      const onEntryPassBtn = () => {
        // 不激活
        // if (formData.value.is_register == '1') {
        CloseStatusDialog();
        emit("obtainShopData", 1);
        // ticketTable.value.tableLoad();
        // } else {
        // 激活
        // entryPassRef.value.openDialog(lastOrderSn.value, 'ticket');
        // }
      }
      /**
       * 
       * 支付成功后点击返回按钮
       * 
       * */
      function CloseStatusDialog() {
        // 清空输入框内容
        barCodeInput.value = "";
        // 关闭支付失败弹框
        errorDialog.value.close();
        // 关闭支付成功弹框
        succesDialog.value.close();
        emit("obtainShopData", 1);
      }
      /**
       * 
       * 支付失败点击返回按钮
       * 
       * */
      function CloseErrorDialog() {
        // 清空输入框扫码内容
        barCodeInput.value = "";
        // 关闭支付失败弹框
        errorDialog.value.close();
        emit("obtainShopData", 1);
      }

      /**
       * 
       * 录入通行证确定按钮
       * 
       * */
      const determine = (data) => {
        entryPassRef.value.dialogRef.isLoading = true;
        ScenicApi.cardReceive(data).then((res) => {
          entryPassRef.value.dialogRef.isLoading = false;
          if (res.Code === 200) {
            ElMessage.success(`录入成功！`);
            entryPassRef.value.automaticSwitching();
            // CloseStatusDialog();
            // ticketTable.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 获取打印数据
       * 
       * */
      const lodopSn = () => {
        OrderApi.printOrder({
          sn: lastOrderSn.value
        }).then(res => {
          if (res.Code == 200) {
            printInfo.value.setDataAndPrint(res.Data);
          }
        })
      }
      const confirm = () => { }

      return {
        allMoney,
        openDialog,
        settleDialog,
        payTypeLoading,
        payType,
        getPayWayCombine,
        checkPayType,
        createShopCartOrder,
        lastPayway,
        loadingDialog,
        CloseLoadingDialog,
        barCodeInfo,
        barCodeInput,
        succesDialog,
        CloseStatusDialog,
        confirmText,
        onEntryPassBtn,
        lastOrderSn,
        barCodeInputRef,
        errorDialog,
        errorTitle,
        CloseErrorDialog,
        confirm,
        entryPassRef,
        determine,
        lodopSn,
        printInfo,
        clearShopCart,
      };
    }
  }

</script>

<style lang="scss" scoped>
  $text-color: #ff7e83;

  .shop-settlement {
    .barcode-input {
      width: 100%;
      margin-top: 30px;
    }

    .status-tips {
      color: var(--text-third-color);
      margin-top: 20px;

      span {
        color: var(--theme-color);
      }
    }

    .dialog-pay {
      h2 {
        padding: 10px;
        color: $text-color;
      }


      .pay-type {
        margin-top: 20px;
        padding-bottom: 60px;
      }

      .pay-icon {
        width: 50px;
        height: 50px;
      }
    }

    .text-center {
      text-align: center;
    }

    .skin-text-color {
      color: var(--text-color);
    }

    .grid-4 {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      cursor: pointer;
    }
  }
</style>